import * as SignalR from '@microsoft/signalr';
import { HubUpdatableData } from './heavydogHubTypes';
import { RepoPipelineSummaries } from './repositories';
import * as ServiceLocator from '@gr/service-locator';

const heavyDogHubUrl = `${ServiceLocator.url('HeavyDog')}/hub`;

export interface HeavyDogHubOptions {
    automaticReconnect?: boolean;
    logLevel?: SignalR.LogLevel;
    accessTokenFactory?: () => string | Promise<string>;
}

class ExponentialBackoffReconnect implements SignalR.IRetryPolicy {
    nextRetryDelayInMilliseconds(retryContext: SignalR.RetryContext): number | null {
        const initialDelays = [2e3, 4e3, 8e3, 16e3, 32e3, 64e3, 128e3, 256e3, 512e3, 1024e3];
        return initialDelays[Math.min(retryContext.previousRetryCount, 9)];
    }
}

class HeavyDogHub {
    private _options = {
        automaticReconnect: true,
        logLevel: SignalR.LogLevel.None,
        transport: SignalR.HttpTransportType.WebSockets | SignalR.HttpTransportType.ServerSentEvents | SignalR.HttpTransportType.LongPolling
    };
    private _connection!: SignalR.HubConnection;
    private _callback!: (repoSummaries?: RepoPipelineSummaries[]) => void;

    public options(options: HeavyDogHubOptions): HeavyDogHub {
        Object.assign(this._options, options);
        return this;
    }

    public callback(callback: (repoSummaries?: RepoPipelineSummaries[]) => void): HeavyDogHub {
        this._callback = callback;
        return this;
    }

    public build(): HeavyDogHub {
        let heavyDogHubBuilder = new SignalR.HubConnectionBuilder().withUrl(heavyDogHubUrl, this._options).configureLogging(this._options.logLevel);
        if (this._options.automaticReconnect) heavyDogHubBuilder = heavyDogHubBuilder.withAutomaticReconnect(new ExponentialBackoffReconnect());
        this._connection = heavyDogHubBuilder.build();
        this._connection.on('update', (updatableData: HubUpdatableData) => {
            this._callback(updatableData?.repoPipelineSummaries ?? undefined);
        });
        // delay was needed here.
        this._connection.onreconnected(() => setTimeout(() => this.OnReconnected(), 500));

        return this;
    }

    public start(): Promise<void> {
        return this._connection.start();
    }

    public stop(): Promise<void> {
        return this._connection.stop();
    }

    public async refresh(): Promise<void> {
        await this._connection.invoke<void>('Refresh');
    }

    public get connection(): SignalR.HubConnection {
        return this._connection;
    }

    private OnReconnected() {
        if (this._connection.state == SignalR.HubConnectionState.Connected) this.refresh();
        else setTimeout(() => this.OnReconnected(), 1000);
    }
}

export { HeavyDogHub };

export default HeavyDogHub;
